import React from "react";
import avatar from "../assets/avatar.png";
import opFor from "../assets/operationFortune.png";
import unchai from "../assets/unchai.png";
import logo from "../assets/logo.png";
import searchIcon from "../assets/searchIcon.png";
import closeIcon from "../assets/closeIcon.png";
import "../styles/Home.css";

export default function Home() {
  return (
    <div style={{ background: "#1E1E1E", width: "100%", paddingBottom: '15px' }}>
      <div>
        <img src={logo} width="5%" />
      </div>
      <div
        style={{
          display: "flex",
          color: "#FFFBFB",
          paddingLeft: "30px",
          paddingRight: "30px",
          justifyContent: "space-between",
          gap: "5%",
        }}
      >
        <div
          style={{
            width: "38%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: '-6%'
          }}
        >
          <div
            style={{
              width: "55%",
              fontWeight: "bolder",
              fontSize: "x-large",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            Discover your movie
          </div>
          <div
            style={{
              display: "flex",
              background: "white",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "55%",
              padding: "5px",
            }}
          >
            <img src={searchIcon} width="15px" />
            <input
              className="input"
              style={{ border: "none", width: "100%" }}
              placeholder="Search"
            />
            <img src={closeIcon} width="20px" />
          </div>
        </div>
        <div style={{ width: "62%", display: "flex", gap: "30px" }}>
          <div>
            <img src={avatar} alt="avatar" title="avatar" height="532px" />
          </div>
          <div>
            <img
              src={opFor}
              alt="opFor"
              title="Operation fortune"
              height="532px"
            />
          </div>
          <div>
            <img src={unchai} alt="unchai" title="Unchai" height="532px" />
          </div>
        </div>
      </div>
    </div>
  );
}
