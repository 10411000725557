import Home from "./Views/Home";


function App() {
  return (
    <Home />
    // <div style={{position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%,-50%)' }}>
    //  Start Your Movie
    // </div>
  );
}

export default App;
